<template>
  <q-header>
    <q-toolbar class="text-white custom-nav" style="width: auto">
      <div class="col-2">
        <a href="homepage" class="heading">zerudite</a>
      </div>
      <div class="col-3"></div>
      <div class="col-3">
        <a href="homepage" class="nav-urls">Home</a>
        <a href="faq" class="nav-urls">FAQs</a>
      </div>
      <div class="col-4" style="margin-left: 5vw;">
        <q-btn @click="$router.replace('/signup')" no-caps push label="Get Started" style="background-color: #F8E9A1; border-radius: 20px; width: 10vw; white-space: nowrap; margin-right: 4vw; color: black; font-family: Montserrat; font-weight: 600"/>
        <q-btn @click="$router.replace('/login')" no-caps push label="Log In" style="background-color: #A8D0E6; border-radius: 20px; width: 8vw; white-space: nowrap; color: black; font-family: Montserrat; font-weight: 600"/>
      </div>

    </q-toolbar>
  </q-header>
</template>

<script>
import {QToolbar} from 'quasar'

export default {
  name: "NavBar",
  components: {
    QToolbar
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');

.heading {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 30px;
  color: black;
  text-decoration: none;
  margin-left: 2vw;
}

.custom-nav {
  background-image: url("../assets/thinnavbartriangles.png");
  background-size: cover;
  background-position: top left;
  height: 8vh;
}

.nav-urls {
  font-family: Montserrat;
  font-weight: 400;
  color: black;
  font-size: 24px;
  text-decoration: none;
  margin-left: 1vw;
  margin-right: 1vw;
  display: inline-block;
}

.nav-urls:hover {
  color: #F8E9A1;
  -webkit-animation: pulse infinite 1s;
  animation: pulse infinite 1s;
}



</style>