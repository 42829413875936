<template>
  <NavBar/>
  <div class="row faq-header">
    <div class="col" style="height: 24vh">
      <p class="text-h3 gentium text-white" style="margin-left: 2.5vw; margin-top: 10vh;">Frequently Asked Questions</p>
      <q-tabs
          v-model="tab"
          dense
          class="text-white"
          active-color="white"
          indicator-color="black"
          align="center"
          active-bg-color="blue-10"
          narrow-indicator
          style="margin-top: 3vh;"
          no-caps
      >
        <q-tab class="tab-label" name="registration" label="Registration and Login"/>
        <q-tab class="tab-label" name="getting-tutored" label="Getting Tutored"/>
        <q-tab class="tab-label" name="tutoring-others" label="Tutoring Others"/>
        <q-tab class="tab-label" name="quality" label="Quality Preservation and Verification"/>
        <q-tab class="tab-label" name="credits" label="Credit Economy"/>
        <q-tab class="tab-label" name="other" label="Other"/>

      </q-tabs>
    </div>
  </div>
  <div class="row">
    <div class="col">
        <q-tab-panels v-model="tab">

          <q-tab-panel name="registration">
            <div class="row">
              <div class="col-4 q-mr-xl">
                <q-scroll-area style="height: 70vh;">
                  <q-tree :nodes="registration_questions" node-key="label" selected-color="blue-10" default-expand-all v-model:selected="selected">

                    <template v-slot:default-header="prop">
                      <div class="row items-center">
                        <div v-if="prop.node.category" class="gentium text-h4 text-blue-10"><b>{{ prop.node.category }}</b>
                        </div>
                        <div v-else class="gentium text-h6">{{ prop.node.question }}</div>
                      </div>
                    </template>

                  </q-tree>
                </q-scroll-area>
              </div>
              <q-separator vertical style="height: 70vh"></q-separator>
              <div class="col-7">
                <q-scroll-area style="height: 70vh;">
                  <q-tab-panels v-model="selected" animated transition-prev="jump-down" transition-next="jump-up">


                    <q-tab-panel name="c1">
                      <p class="text-h2 gentium text-blue-10">About Zerudite</p>
                      <p class="text-h6 gentium">This category includes questions about our service as a whole and
                        describes what we have to offer for all of our students, as well as what makes us different from
                        the "staple" tutoring agency.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q1">
                      <p class="text-h2 gentium text-blue-10">What is Zerudite?</p>
                      <p class="text-h6 gentium">Zerudite is a free-to-use, live tutoring service for students, designed
                        by students, where students tutor students. After you sign up, you will be able to start
                        scheduling tutoring sessions. While you will start with 500 credits as soon as you sign up, you
                        will receive an extra 1000 credits to use upon verifying your identity. You can earn credits by tutoring others as well.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q2">
                      <p class="text-h2 gentium text-blue-10">What makes Zerudite unique?</p>
                      <p class="text-h6 gentium">Zerudite is a credit based service, meaning that the live tutoring we
                        offer is customizable! You can earn credits by tutoring, or purchase credits for money.
                        Conversely, you can spend your credits to get tutored, or redeem them for rewards (or volunteer hours)! This
                        students-mentoring-students system is what makes Zerudite unique.
                      </p>
                    </q-tab-panel>


                    <q-tab-panel name="c2">
                      <p class="text-h2 gentium text-blue-10">Login and Signup</p>
                      <p class="text-h6 gentium">This category includes information regarding how you sign up for a Zerudite account and log in to your Zerudite account.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q1">
                      <p class="text-h2 gentium text-blue-10">How do I sign up for Zerudite and create an account?</p>
                      <p class="text-h6 gentium">At the top right corner of the page, you should see a <i>Get
                        Started</i> button. Clicking on it will take you to the signup page. After you complete the fields, your account will be ready. To unlock the full power of Zerudite and to receive the 1000 bonus credits, you must verify your identity.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q2">
                      <p class="text-h2 gentium text-blue-10">How do I log in to my Zerudite account?</p>
                      <p class="text-h6 gentium">At the top right corner of the page, you should see a <i>Log In</i> button. Clicking on it will take you to the login page, where you will be asked to enter your username and password.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q3">
                      <p class="text-h2 gentium text-blue-10">I forgot my Zerudite account password. What should I do?</p>
                      <p class="text-h6 gentium">On the login page, you will see a <i>Forgot Password</i> link. Clicking on this link will direct you to a form where you will be asked to enter your email to recover your password.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q4">
                      <p class="text-h2 gentium text-blue-10">How do I verify myself on Zerudite? What are the benefits of this?</p>
                      <p class="text-h6 gentium">Upon completing all signup fields, you will be asked to submit a photo of your school ID. If you do so, you will automatically receive a bonus 1000 credits. This is also one of the two steps to get access to becoming a tutor!
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q5">
                      <p class="text-h2 gentium text-blue-10">My school did not give me a student ID. What should I do?</p>
                      <p class="text-h6 gentium">You should contact your school administrator to provide you with a valid proof of your attendance at your school, then email us for further instructions.
                        </p>
                    </q-tab-panel>

                  </q-tab-panels>
                </q-scroll-area>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="getting-tutored">
            <div class="row">
              <div class="col-4 q-mr-xl">
                <q-scroll-area style="height: 70vh;">
                  <q-tree :nodes="getting_tutored_questions" node-key="label" selected-color="blue-10" default-expand-all v-model:selected="selected">

                    <template v-slot:default-header="prop">
                      <div class="row items-center">
                        <div v-if="prop.node.category" class="gentium text-h4 text-blue-10"><b>{{ prop.node.category }}</b>
                        </div>
                        <div v-else class="gentium text-h6">{{ prop.node.question }}</div>
                      </div>
                    </template>

                  </q-tree>
                </q-scroll-area>
              </div>
              <q-separator vertical style="height: 50vh"></q-separator>
              <div class="col-7">
                <q-scroll-area style="height: 70vh;">
                  <q-tab-panels v-model="selected" animated transition-prev="jump-down" transition-next="jump-up">

                    <q-tab-panel name="c1">
                      <p class="text-h2 gentium text-blue-10">About Zerudite</p>
                      <p class="text-h6 gentium">This category includes questions about how you can create a tutoring request and what to do if your situation is urgent or if you don't have enough credits.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q1">
                      <p class="text-h2 gentium text-blue-10">How do I create a tutoring request?</p>
                      <p class="text-h6 gentium">First, navigate to the Requests page. From there, go to the Request a Session tab and fill out the form.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q2">
                      <p class="text-h2 gentium text-blue-10">How specific should I be in what I need help on?</p>
                      <p class="text-h6 gentium">Try to be as specific as you can! You can also include links for extra information in the question.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q3">
                      <p class="text-h2 gentium text-blue-10">I need help on a topic fast. How can I get a tutoring session quickly?</p>
                      <p class="text-h6 gentium">You can choose to use some more of your credits to put a higher bounty on your tutoring request.
                                                 This will attract more tutors to accept your offer. Requests with higher bounties are likely to be accepted faster.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q4">
                      <p class="text-h2 gentium text-blue-10">I don’t have enough credits to place a tutoring request. What should I do?</p>
                      <p class="text-h6 gentium">You can purchase more credits in the shop or tutor others to earn credits.
                      </p>
                    </q-tab-panel>


                    <q-tab-panel name="c2">
                      <p class="text-h2 gentium text-blue-10">Receiving help</p>
                      <p class="text-h6 gentium">This category includes information regarding tutoring sessions, and what actions to take after one.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q1">
                      <p class="text-h2 gentium text-blue-10">What happens once my tutoring request is approved?</p>
                      <p class="text-h6 gentium">You will see your newly accepted request in the Accepted Requests section. A session will be generated and opened at the correct time as well.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q2">
                      <p class="text-h2 gentium text-blue-10">How do I get tutored?</p>
                      <p class="text-h6 gentium">First, navigate to the Requests page. From there, go to the Request a Session tab and fill out the form.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q3">
                      <p class="text-h2 gentium text-blue-10">What do I do if I am not satisfied with the help I received?</p>
                      <p class="text-h6 gentium">Please leave accurate feedback in the feedback form after the session to help us address this issue.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q4">
                      <p class="text-h2 gentium text-blue-10">How long does a tutoring session take?</p>
                      <p class="text-h6 gentium">A tutoring session lasts up to 2 hours, but the student can end it at any time. Once you are both satisfied, click the End Session button to finish up.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q5">
                      <p class="text-h2 gentium text-blue-10">What happens if I miss my tutoring session?</p>
                      <p class="text-h6 gentium">You will receive a penalty of 100 credits for missing tutoring sessions. This is as to not waste anyone's time. </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q6">
                      <p class="text-h2 gentium text-blue-10">Do I have to do anything after a tutoring session?</p>
                      <p class="text-h6 gentium">Yes. You will be given a feedback form to fill out.    </p>
                    </q-tab-panel>

                  </q-tab-panels>
                </q-scroll-area>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="tutoring-others">
            <div class="row">
              <div class="col-4 q-mr-xl">
                <q-scroll-area style="height: 70vh;">
                  <q-tree :nodes="tutoring_others_questions" node-key="label" selected-color="blue-10" default-expand-all v-model:selected="selected">

                    <template v-slot:default-header="prop">
                      <div class="row items-center">
                        <div v-if="prop.node.category" class="gentium text-h4 text-blue-10"><b>{{ prop.node.category }}</b>
                        </div>
                        <div v-else class="gentium text-h6">{{ prop.node.question }}</div>
                      </div>
                    </template>

                  </q-tree>
                </q-scroll-area>
              </div>
              <q-separator vertical style="height: 50vh"></q-separator>
              <div class="col-7">
                <q-scroll-area style="height: 70vh;">
                  <q-tab-panels v-model="selected" animated transition-prev="jump-down" transition-next="jump-up">

                    <q-tab-panel name="c1">
                      <p class="text-h2 gentium text-blue-10">Prerequisites</p>
                      <p class="text-h6 gentium">This category includes information regarding what needs to be done before you can start tutoring others.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q1">
                      <p class="text-h2 gentium text-blue-10">Do I need to verify myself to be able to tutor others?</p>
                      <p class="text-h6 gentium">Yes. By asking you to submit a photo of your school ID, we are ensuring that tutors and students alike are real students willing to help each other. You also need to fill out your subjects profile, which allows people to see which subjects you are proficient in.
                      </p>
                    </q-tab-panel>


                    <q-tab-panel name="c2">
                      <p class="text-h2 gentium text-blue-10">Accepting a tutoring request</p>
                      <p class="text-h6 gentium">This category includes questions about interacting with existing tutoring requests.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q1">
                      <p class="text-h2 gentium text-blue-10">How do I look at tutoring requests?</p>
                      <p class="text-h6 gentium">Go to the Search page once inside the app. From there, you can search for open tutoring requests. There may also be some recommended requests for you!
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q2">
                      <p class="text-h2 gentium text-blue-10">How do I accept a tutoring request?</p>
                      <p class="text-h6 gentium">When you see a request, it will have a button to accept the request.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q3">
                      <p class="text-h2 gentium text-blue-10">What should I do if I accept a tutoring request that I cannot fulfill?</p>
                      <p class="text-h6 gentium">You can cancel a tutoring session that you have accepted up to 24 hours before the session.
                      </p>
                    </q-tab-panel>


                    <q-tab-panel name="c3">
                      <p class="text-h2 gentium text-blue-10">Tutoring others</p>
                      <p class="text-h6 gentium">This category includes questions about tutoring another student during a session.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c3q1">
                      <p class="text-h2 gentium text-blue-10">How do I tutor others?</p>
                      <p class="text-h6 gentium">First, you must select a request and accept it. Then log into Zerudite at the time of the session, and you will automatically be directed to a meeting.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c3q2">
                      <p class="text-h2 gentium text-blue-10">What should I do if I can’t help the person adequately?</p>
                      <p class="text-h6 gentium">That's alright! Make sure that you try your best to help the person by researching their question ahead of time and coming prepared. Please remember, however, that a pattern of negative reviews may lead to a ban.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c3q3">
                      <p class="text-h2 gentium text-blue-10">How long will the tutoring session take?</p>
                      <p class="text-h6 gentium">A tutoring session lasts up to 2 hours, but the student can end it at any time. Once you are both satisfied, click the End Session button to finish up.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c3q4">
                      <p class="text-h2 gentium text-blue-10">What happens if I miss my tutoring session?</p>
                      <p class="text-h6 gentium">You will receive a penalty of 100 credits for missing tutoring sessions. This is as to not waste anyone's time.
                      </p>
                    </q-tab-panel>

                  </q-tab-panels>
                </q-scroll-area>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="quality">
            <div class="row">
              <div class="col-4 q-mr-xl">
                <q-scroll-area style="height: 70vh;">
                  <q-tree :nodes="quality_preservation_questions" node-key="label" selected-color="blue-10" default-expand-all v-model:selected="selected">

                    <template v-slot:default-header="prop">
                      <div class="row items-center">
                        <div v-if="prop.node.category" class="gentium text-h4 text-blue-10"><b>{{ prop.node.category }}</b>
                        </div>
                        <div v-else class="gentium text-h6">{{ prop.node.question }}</div>
                      </div>
                    </template>

                  </q-tree>
                </q-scroll-area>
              </div>
              <q-separator vertical style="height: 50vh"></q-separator>
              <div class="col-7">
                <q-scroll-area style="height: 70vh;">
                  <q-tab-panels v-model="selected" animated transition-prev="jump-down" transition-next="jump-up">

                    <q-tab-panel name="c1">
                      <p class="text-h2 gentium text-blue-10">Tutoring Quality</p>
                      <p class="text-h6 gentium">This category includes questions about how you and we can work together to ensure that Zerudite remains a high quality tutoirng service.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q1">
                      <p class="text-h2 gentium text-blue-10">Zerudite is a student-to-student tutoring platform. How do I ensure that tutoring is of high quality?</p>
                      <p class="text-h6 gentium">All tutors must have verified themselves by submitting a photo of their school ID and filled out their subject profile. Zerudite also prunes bad tutors by banning users who have a had a history of bad reviews.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q2">
                      <p class="text-h2 gentium text-blue-10">How does Zerudite reprimand members who are not properly tutoring people, or “scamming” for credits?</p>
                      <p class="text-h6 gentium">At the end of each session, both parties are given a mandatory feedback form. Students and tutors who do not behave well and interact well have high chances of receiving consistently bad feedback. Patterns of bad feedback lead to bans from Zerudite, ensuring that our platform is a high-quality learning environment.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q3">
                      <p class="text-h2 gentium text-blue-10">How does Zerudite ensure that tutoring sessions are of high quality and prevent wrongdoing during sessions?</p>
                      <p class="text-h6 gentium">Students that have taken courses in subjects you want to be tutored in can be just as knowledgeable and helpful.
                                                 All students that tutor on Zerudite must be verified. In the case that a tutoring session is not fruitful or the
                                                 tutor was not knowledgeable on the topic, you can address this in the feedback form, which will be counted as a negative review. Consistent patterns of negative reviews lead to bans.
                      </p>
                    </q-tab-panel>


                  </q-tab-panels>
                </q-scroll-area>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="credits">
            <div class="row">
              <div class="col-4 q-mr-xl">
                <q-scroll-area style="height: 70vh;">
                  <q-tree :nodes="credit_economy_questions" node-key="label" selected-color="blue-10" default-expand-all v-model:selected="selected">

                    <template v-slot:default-header="prop">
                      <div class="row items-center">
                        <div v-if="prop.node.category" class="gentium text-h4 text-blue-10"><b>{{ prop.node.category }}</b>
                        </div>
                        <div v-else class="gentium text-h6">{{ prop.node.question }}</div>
                      </div>
                    </template>

                  </q-tree>
                </q-scroll-area>
              </div>
              <q-separator vertical style="height: 50vh"></q-separator>
              <div class="col-7">
                <q-scroll-area style="height: 70vh;">
                  <q-tab-panels v-model="selected" animated transition-prev="jump-down" transition-next="jump-up">

                    <q-tab-panel name="c1">
                      <p class="text-h2 gentium text-blue-10">Spending and earning credits</p>
                      <p class="text-h6 gentium">This category includes information about how you can spend and earn credits.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q1">
                      <p class="text-h2 gentium text-blue-10">How do I earn credits?</p>
                      <p class="text-h6 gentium">You can earn credits by tutoring other students. You can also buy credits with real money.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q2">
                      <p class="text-h2 gentium text-blue-10">Do I get bonus credits for verification?</p>
                      <p class="text-h6 gentium">Yes - you earn 1000 bonus credits for free when you verify yourself.
                      </p>
                    </q-tab-panel>

                    <q-tab-panel name="c1q3">
                      <p class="text-h2 gentium text-blue-10">How do I spend credits?</p>
                      <p class="text-h6 gentium">When you ask to be tutored, you place a bounty on your tutoring session.
                                                When your offer is accepted by a tutor, you give the amount of credits placed as a bounty to the tutor.
                                                In addition, if you have accumulated a large sum of credits, you can redeem them for rewards and volunteer hours.
                      </p>
                    </q-tab-panel>


                    <q-tab-panel name="c2">
                      <p class="text-h2 gentium text-blue-10">Credit Redemption</p>
                      <p class="text-h6 gentium">This category includes information about how you can redeem your credits.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c2q1">
                      <p class="text-h2 gentium text-blue-10">I have lots of credits. What should I do with them if I don’t need to get tutored?</p>
                      <p class="text-h6 gentium">If you don't need to get tutored, you can redeem credits for real world rewards and volunteer hours!
                      </p>
                    </q-tab-panel>


                    <q-tab-panel name="c3">
                      <p class="text-h2 gentium text-blue-10">Credit Purchases</p>
                      <p class="text-h6 gentium">This category includes information about buying credits.</p>
                    </q-tab-panel>

                    <q-tab-panel name="c3q1">
                      <p class="text-h2 gentium text-blue-10">I need more credits. What should I do if I cannot tutor others but need help myself?</p>
                      <p class="text-h6 gentium">You can buy credits from the shop at any time.
                      </p>
                    </q-tab-panel>

                  </q-tab-panels>
                </q-scroll-area>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="other">
            <div class="row">
              <div class="col-4 q-mr-xl">
                <q-scroll-area style="height: 70vh;">
                  <q-tree :nodes="other_questions" node-key="label" selected-color="blue-10" default-expand-all v-model:selected="selected">

                    <template v-slot:default-header="prop">
                      <div class="row items-center">
                        <div v-if="prop.node.category" class="gentium text-h4 text-blue-10"><b>{{ prop.node.category }}</b>
                        </div>
                        <div v-else class="gentium text-h6">{{ prop.node.question }}</div>
                      </div>
                    </template>

                  </q-tree>
                </q-scroll-area>
              </div>
              <q-separator vertical style="height: 50vh"></q-separator>
              <div class="col-7">
                <q-scroll-area style="height: 70vh;">
                  <q-tab-panels v-model="selected" animated transition-prev="jump-down" transition-next="jump-up">

                    <q-tab-panel name="q1">
                      <p class="text-h2 gentium text-blue-10">I would like to support Zerudite! How can I do so?</p>
                      <p class="text-h6 gentium">You can support us by telling others to make an account and join our network!</p>
                    </q-tab-panel>


                  </q-tab-panels>
                </q-scroll-area>
              </div>
            </div>
          </q-tab-panel>

        </q-tab-panels>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue"
import { ref } from 'vue'

export default {
  setup () {
    return {
      selected: ref('c1'),
      tab: ref('registration'),
      registration_questions: [
        {
          label: "c1",
          category: "About Zerudite",
          children: [
            {
              label: "c1q1",
              question: "What is Zerudite?"
            },
            {
              label: "c1q2",
              question: "What makes Zerudite unique?"
            }
          ]
        },
        {
          label: "c2",
          category: "Login and Signup",
          children: [
            {
              label: "c2q1",
              question: "How do I sign up for Zerudite and create an account?"
            },
            {
              label: "c2q2",
              question: "How do I log in to my Zerudite account?"
            },
            {
              label: "c2q3",
              question: "I forgot my Zerudite account password. What should I do?"
            },
            {
              label: "c2q4",
              question: "How do I verify myself on Zerudite? What are the benefits of this?"
            },
            {
              label: "c2q5",
              question: "My school did not give me a student ID. What should I do?"
            }
          ]
        }
      ],
      getting_tutored_questions: [
        {
          label: "c1",
          category: "Creating a tutoring request",
          children: [
            {
              label: "c1q1",
              question: "How do I create a tutoring request?"
            },
            {
              label: "c1q2",
              question: "How specific should I be in what I need help on?"
            },
            {
              label: "c1q3",
              question: "I need help on a topic fast. How can I get a tutoring session quickly?"
            },
            {
              label: "c1q4",
              question: "I don’t have enough credits to place a tutoring request. What should I do?"
            },
          ]
        },
        {
          label: "c2",
          category: "Receiving help",
          children: [
            {
              label: "c2q1",
              question: "What happens once my tutoring request is approved?"
            },
            {
              label: "c2q2",
              question: "How do I get tutored?"
            },
            {
              label: "c2q3",
              question: "What do I do if I am not satisfied with the help I received?"
            },
            {
              label: "c2q4",
              question: "How long does a tutoring session take?"
            },
            {
              label: "c2q5",
              question: "What happens if I miss my tutoring session?"
            },
            {
              label: "c2q6",
              question: "Do I have to do anything after a tutoring session?"
            },
          ]
        }
      ],
      tutoring_others_questions: [
        {
          label: "c1",
          category: "Prerequisites",
          children: [
            {
              label: "c1q1",
              question: "Do I need to verify myself to be able to tutor others?"
            }
          ]
        },
        {
          label: "c2",
          category: "Accepting a tutoring request",
          children: [
            {
              label: "c2q1",
              question: "How do I look at tutoring requests?"
            },
            {
              label: "c2q2",
              question: "How do I accept a tutoring request?"
            },
            {
              label: "c2q3",
              question: "What should I do if I accept a tutoring request that I cannot fulfill?"
            }
          ]
        },
        {
          label: "c3",
          category: "Creating a tutoring request",
          children: [
            {
              label: "c3q1",
              question: "How do I tutor others?"
            },
            {
              label: "c3q2",
              question: "What should I do if I can’t help the person adequately?"
            },
            {
              label: "c3q3",
              question: "How long will the tutoring session take?"
            },
            {
              label: "c3q4",
              question: "What happens if I miss my tutoring session?"
            },
          ]
        }
      ],
      quality_preservation_questions: [
        {
          label: "c1",
          category: "Tutoring Quality",
          children: [
            {
              label: "c1q1",
              question: "Zerudite is a student-to-student tutoring platform. How do I ensure that tutoring is of high quality?"
            },
            {
              label: "c1q2",
              question: "How does Zerudite reprimand members who are not properly tutoring people, or “scamming” for credits?"
            },
            {
              label: "c1q3",
              question: "How does Zerudite ensure that tutoring sessions are of high quality and prevent wrongdoing during sessions?"
            }
          ]
        },
      ],
      credit_economy_questions: [
        {
          label: "c1",
          category: "Spending and earning credits",
          children: [
            {
              label: "c1q1",
              question: "How do I earn credits?"
            },
            {
              label: "c1q2",
              question: "Do I get bonus credits for verification?"
            },
            {
              label: "c1q3",
              question: "How do I spend credits?"
            }
          ]
        },
        {
          label: "c2",
          category: "Credit Redemption",
          children: [
            {
              label: "c2q1",
              question: "I have lots of credits. What should I do with them if I don’t need to get tutored?"
            },
          ]
        },
        {
          label: "c3",
          category: "Spending and earning credits",
          children: [
            {
              label: "c3q1",
              question: "Zerudite is a student-to-student tutoring platform. How do I ensure that tutoring is of high quality?"
            }
          ]
        }
      ],
      other_questions: [
        {
          label: "q1",
          question: "I would like to support Zerudite! How can I do so?"
        }
      ]
    }
  },
  name: "FAQPage",
  components: {
    NavBar
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Gentium+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@font-face {
  font-family: "Farray";
  src: url("../assets/fonts/FARRAY.otf");
}

.faq-header {
  background-image: url("../assets/faqheader2.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.gentium {
  font-family: Lato;
}

.tab-label {
  font-family: Montserrat;
}

</style>